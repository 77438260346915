<template>
  <div class="contact inner" id="js-contact">
    <div class="inner">
      <div class="contact__form" id="js-form">
        <iframe
          frameborder="0"
          scrolling="auto"
          src="https://pro.form-mailer.jp/fms/fbce84e4254294"
          title="HTML Form"
          width="100%"
          height="100%"
        >
          <a
            href="https://pro.form-mailer.jp/fms/fbce84e4254294"
            title="Contact"
            >この部分はインラインフレームを使用しています</a
          >
        </iframe>
      </div>
    </div>
  </div>
</template>
<script>
import scrollAnimMixin from '../../mixins/scrollAnimMixin.js'
export default {
  mixins: [scrollAnimMixin],
  head: {
    title: {
      inner: 'お問い合わせ'
    },
    meta: [
      { property: 'og:title', content: 'お問い合わせ｜GENBA SHIKO' },
      {
        name: 'description',
        content:
          '株式会社Luciで展開するソリューション事業へのお問い合わせフォームです。'
      },
      {
        property: 'og:description',
        content:
          '株式会社Luciで展開するソリューション事業へのお問い合わせフォームです。'
      },
      { property: 'og:url', content: 'https://luci.co.jp/genbashiko/contact' },
      { property: 'og:type', content: 'article' }
    ]
  },
  mounted: function () {
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  methods: {
    resize: function () {
      let winW = document.body.offsetWidth
      if (winW <= 768) {
        let inner = document.querySelector('#js-contact'),
          innerW = inner.offsetWidth,
          scale = innerW / 465,
          form = document.querySelector('#js-form'),
          height = scale * (1100 + 100)

        form.style.transform = `scale(${scale})`
        inner.style.height = height + 'px'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.contact {
  margin: 110px auto;
  height: auto;
  .inner {
    height: 100%;
    overflow: hidden;
  }
  &__form {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 70%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
@media only screen and (max-width: 768px) {
  .contact {
    margin: 60px auto 30px;
    overflow: hidden;
    .inner {
      width: 100%;
      min-width: 465px;
    }
    &__form {
      transform-origin: top left;
      width: 465px;
      height: 1100px;
      iframe {
      }
    }
  }
}
</style>
